#scroll {
    overflow-x: scroll;
    padding: 2px;
    /* overflow-y: scroll; */
}

#scroll::-webkit-scrollbar {
    height: 2px;
    width: 3px;
    /* width of the entire scrollbar */
}

#scroll::-webkit-scrollbar-track {
    background: rgb(231, 228, 223);
    display: none;
}

#scroll::-webkit-scrollbar-thumb {
    background-color: #2196f3;

    border-radius: 20px;
    /* roundness of the scroll thumb
        /* display: none; */
}